<template>
  <div>
    <div class="topbgs colw padd5" v-if="cwidth < 560">
      <div class="paddzy20">
        <van-row class="">
          <van-col span="7" class="">
            <van-icon :name="require('@/assets/sxlogo.png')" size="26"></van-icon>
            <span class="font18 ml5 fontblod" style="vertical-align: bottom;">SIXU</span>
            <!--              <van-image :src="require('@/assets/banner.png')" height="85vh" width="98vw"></van-image>-->
          </van-col>
          <van-col span="3" class="mcenter font14 padd8" @click="toHome">首页</van-col>
          <van-col span="14" class="font14 padd8">
            <van-row>
              <van-col v-for="(it,ke) in navlist" :key="ke"><span @click="handleLeft(ke)" class="mycursor mr8">{{ it }}</span></van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row class="mt5 padd10" >
          <van-col span="12" class="font15 fontblod line20">
            <p class="mt10">助力企业数智化</p>
            <p class="mt7">为中小企业提供高效的协同管理解决方案</p>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="topbg colw padd10" v-else-if="cwidth >= 560 && cwidth < 850">
      <div class="paddzy20">
        <van-row class="">
          <van-col offset="1" span="4" class="">
            <van-icon :name="require('@/assets/sxlogo.png')" size="28"></van-icon>
            <span class="font18 ml5 fontblod" style="vertical-align: bottom;">SIXU</span>
            <!--              <van-image :src="require('@/assets/banner.png')" height="85vh" width="98vw"></van-image>-->
          </van-col>
          <van-col span="16" class="font14 padd8">
            <van-row>
              <van-col v-for="(it,ke) in navlist" :key="ke"><span @click="handleLeft(ke)" class="mycursor mr10">{{ it }}</span></van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row class="mt50 padd10" >
          <van-col offset="1" span="12" class="font18 fontblod line20">
            <p class="mt10">助力企业数智化</p>
            <p class="mt10">为中小企业提供高效的协同管理解决方案</p>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-row id="id0" class="paddzy20">
      <van-col span="24" class="mcenter">
        <p class="font18 mt12">应用可视化搭建，根据业务灵活搭建</p>
        <van-row  class="">
          <van-col span="24" style="width: 100%;display: flex;justify-content: center;">
            <div class="padd10" style="width: 240px;">
              <van-tabs v-model="active">
                <van-tab title="表单设计"></van-tab>
                <van-tab title="流程设计"></van-tab>
                <van-tab title="报表设计"></van-tab>
              </van-tabs>
            </div>
          </van-col>
        </van-row>
        <div v-if="active == 0">
          <p class="font13 padd20 line20">丰富多样的表单组件，可自定义组件属性，如数据来源、规则校验等，移动端也支持表单基础配置</p>
          <div class="mt10" @click="imagePre(1,0)">
            <van-image :src="require('@/assets/home/one_1.png')" width="70vw"></van-image>
          </div>
        </div>
        <div v-if="active == 1">
          <p class="font13 padd20 line20">图形式流程搭建，支持多种类型环节（如审批环节、处理环节、触发更新等）和各类复杂条件流程，满足多种业务场景需求</p>
          <div class="mt10" @click="imagePre(1,1)">
            <van-image :src="require('@/assets/home/one_3.png')" width="70vw"></van-image>
          </div>
        </div>
        <div v-if="active == 2" @click="imagePre(1,2)">
          <p class="font13 padd20 line20">自定义报表设计器，解决企业数据分析应用痛点（开发效率低，支付周期长，权限管理混乱），支持多维度数据分析，辅助管理决策</p>
          <div class="mt10">
            <van-image :src="require('@/assets/home/one_2.png')" width="60vw"></van-image>
          </div>
        </div>
      </van-col>
    </van-row>
    <div style="background-color: #F4F7FC;">
      <div class="padd20 mcenter mtb20" id="id1">
        <p class="font18 mt20 mb10">集成平台基础架构快速赋能</p>
        <p class="font13 padd10">提供底层基础功能支持，为企业协同高效管理保驾护航</p>
        <div class="padd20" style="margin: auto;">
          <div class="ml2vw">
            <div class="ftleft" style="width: 19vw;">
              <div class="">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img1.png')" height="30px" width="30px" class="mt12"></van-image>
                </div>
                <p class="font14 padd15">组织架构</p>
                <!--                <p class="font13 col6 lineh15">四序支持建立企业通讯录，流程审批科技与组织架构，方便、快捷</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 19vw">
              <div class="">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img2.png')" height="30px" width="30px" class="mt12"></van-image>
                </div>
                <p class="font14 padd15">权限管理</p>
                <!--                <p class="font13 col6 lineh15">权限严格，读写查分权明确，组织、角色、操作、模块、对象、数据等权限的任意组合，有效支撑各种权限需求</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 19vw">
              <div class="">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img3.png')" height="30px" width="30px" class="mt12"></van-image>
                </div>
                <p class="font14 padd15">安全聊天</p>
                <!--                <p class="font13 col6 lineh15">基于组织架构，通知、预警、里程碑等多种消息根据具体需求和场景随意配置</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 19vw">
              <div class="">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img4.png')" height="30px" width="30px" class="mt12"></van-image>
                </div>
                <p class="font14 padd15">知识库</p>
                <!--                <p class="font13 col6 lineh15">打通百度网盘，无缝对接授权企业网盘，轻松实现企业文档、协同文档、项目文档等上传、下载、查看一站式管理</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 19vw">
              <div class="">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img5.png')" height="30px" width="30px" class="mt12"></van-image>
                </div>
                <p class="font14 padd15">工作台</p>
                <!--                <p class="font13 col6 lineh15">多样化工作台，实现以事情为中心，无需切换组织也可以轻松处理待办事项和接受消息提醒</p>-->
              </div>
            </div>
            <div class="clear"></div>
          </div>


        </div>
      </div>

      <div class="paddzy10 mcenter">
        <div class="font18 mt10 mb10">平台特色管理模式</div>
        <div class="padd30 font13">对比市场其他同类竞品，四序拥有独特的管理模式，打破传统固有思想，为客户创造价值，让企业更加智慧，助力企业实现数智化</div>
        <div class="padd20">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" ref="swipe">
            <van-swipe-item v-for="(item,key) in swipes" :key="key">
              <div class="" style="margin: auto">
                <div @click="imagePre(2,key)">
                    <van-image v-if="key == 0" :src="require('@/assets/home/two_1.png')"  width="80vw"></van-image>
                    <van-image v-else-if="key == 1" :src="require('@/assets/home/two_2.png')"  width="80vw"></van-image>
                    <van-image v-else-if="key == 2" :src="require('@/assets/home/two_3.png')"  width="80vw"></van-image>
                    <van-image v-else-if="key == 3" :src="require('@/assets/home/two_4.png')"  width="80vw"></van-image>
                 </div>
                <div>
                  <div class="paddzy10 mleft">
                    <p class="font15 padd10 ">{{ item.title }}</p>
                    <p class="font14 line20">{{ item.text }}</p>
                  </div>
<!--                  <div class="mt20 paddzy25 mr20">
                    <div class="bgimg ftright ml20" @click="next">
                      <van-icon name="arrow" color="#1890ff" class="mt5"/>
                    </div>
                    <div class="bgimg ftright" @click="back">
                      <van-icon name="arrow-left" color="#1890ff" class="mt5"/>
                    </div>
                  </div>-->
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>

      </div>
      <div style="height: 100px;">
        <van-image :src="require('@/assets/home/back.png')" style="height: 100px;width: 100%;"></van-image>
      </div>
    </div>
    <div class="mt20 mcenter">
      <p class="font18 padd20">开箱即用的解决方案</p>
      <p class="font13 mb30 line20">行业化解决方案，并能根据您的需求提供灵活、高效的贴身定制服务，让最终交付的方案能够完全贴合您的业务</p>
      <div style="margin: auto;" class="text paddzy10">
        <van-row class="mleft mb20" v-for="(ite,ke) in list" :key="ke">
          <van-col span="12" class="" v-for="(it,k) in ite" :key="k">
            <div class="border">
              <div class="p10">
                <div class="">
                  <div :class="['ftleft','bors', it.bg]"></div>
                  <div class="ftleft ml10 font14">{{ it.title }}</div>
                  <div class="clear"></div>
                </div>
                <div class="font12 line20  mt10 text" :title="it.text">{{ it.text }}</div>
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="mt10 mcenter bgwhite ml2vw" id="id2">
      <p class="font18 padd20 mb15">实施保障</p>
      <div class="" style="margin: auto;height: 220px;">
        <div class="wid20 ftleft mcenter" v-for="(item,key) in step" :key="key">
          <div class="bor70" style="margin: auto;">
            <van-icon v-if="key==0" :name="require('@/assets/home/icon1.png')" size="35" class="mt7"></van-icon>
            <van-icon v-if="key==1" :name="require('@/assets/home/icon2.png')" size="35" class="mt7"></van-icon>
            <van-icon v-if="key==2" :name="require('@/assets/home/icon3.png')" size="35" class="mt7"></van-icon>
            <van-icon v-if="key==3" :name="require('@/assets/home/icon4.png')" size="35" class="mt7"></van-icon>
            <van-icon v-if="key==4" :name="require('@/assets/home/icon5.png')" size="33" class="mt10"></van-icon>
          </div>
          <div class="padd20 mt10">
            <div class="line" style="display: flex;justify-content: center;position: relative;">
              <div class="bor10 mcenter colw" style="position: absolute;top: -12px;" >
                <span style="position: absolute;top: 4px;left: 8px;">{{ key + 1 }}</span>
              </div>
            </div>
          </div>

          <div class="padd10 font14">{{ item.title }}</div>
          <div class="paddzy5 line20 font12">{{ item.text }}</div>
        </div>
      </div>
      <!--        <van-image :src="require('@/assets/home/bottom.png')" style="height: 600px;width: 100%;"></van-image>-->
    </div>
    <div style="background-color: #F4F7FC;">
      <van-row class="mt20 mcenter padd5" >
        <van-col span="8">
          <van-image :src="require('@/assets/sixu2.png')" height="20vw" width="20vw" class="mt7"></van-image>
          <p class="font14">联系我们</p>
        </van-col>
        <van-col span="8">
          <van-image :src="require('@/assets/sixuma.png')" height="20vw" width="20vw" class="mt7"></van-image>
          <p class=" font14">关注公众号</p>
        </van-col>
        <van-col span="8">
          <div @click="toDolown">
            <van-image :src="require('@/assets/sixu.png')" height="20vw" width="20vw" class="mt7"></van-image>
            <p class=" font14">点击下载APP</p>
          </div>
        </van-col>
      </van-row>
      <van-row class="mt5 mcenter" style="background-color: #F4F7FC;">
        <van-col span="24" class="  font16">
          <p class="padd10">成都四小牛科技有限公司</p>
          <a href="https://beian.miit.gov.cn/">蜀ICP备2021025007号</a>
        </van-col>
<!--        <van-col span="6">
          <van-image :src="require('@/assets/sixu2.png')" height="20vw" width="20vw" class="mt7"></van-image>
          <p class="font14">联系我们</p>
        </van-col>
        <van-col span="6">
          <van-image :src="require('@/assets/sixuma.png')" height="20vw" width="20vw" class="mt7"></van-image>
          <p class=" font14">关注公众号</p>
        </van-col>-->
      </van-row>
    </div>

    <div style="padding: 10px;background-color: #F4F7FC;"></div>

  </div>
</template>

<script>
import {ImagePreview} from "vant";
export default {
  name: "h5Index",
  props: ['swipes','step','navlist','cwidth'],
  data(){
    return{
      active: "",
      activeitem: 0,
      navgatorIndex: 0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      list: [
        [
          {bg: 'bg4',title: "工程项目管理",text: "招投标、立项、方案设计、准备、执行、监控、结项；个性化设置流程"},
          {bg: 'bg2',title: "招投标管理",text: "投标过程节点（做资料、封标等）落实到人，开标时间全程（打保证金、做资料、封开、开标）预警提醒，成本报表展示各环节产生费用统计和明细"},
        ],
        [
          {bg: 'bg3',title: "供应商管理",text: "实现供应商全方位闭环管理，提高采购效率，降低采购成本"},
          {bg: 'bg1',title: "进销存",text: "供应商、采购、库存、销售、财务、内部管理等全场景覆盖，实现进销存闭环管理；支持完全自定义，就能制造企业高效管理"},
        ],
        [
          {bg: 'bg5',title: "设备巡检",text: "一物一码，一台手机就能查看最新档案；设备档案管理、保修、巡检、保养，全流程覆盖，随时监控"},
          {bg: 'bg6',title: "生产管理",text: "设备维护、销售订单、物料分析、原料采购、生产计划及实施、质量检测、库存及财务管理，一应俱全的生产管理"},
        ],
        [
          {bg: 'bg7',title: "客户管理",text: "从线索到回款，赢单效率大幅提升；移动办公，无需电脑；短信、邮件、微信，多种消息触达客户；多维度报表，助力精细化运营"},
          {bg: 'bg8',title: "售后工单",text: "只能派单、服务监控、费用结算、客户反馈、客户、备件、网点管理，无死角覆盖售后全场景"},
        ],
      ],
      img1: [require('/src/assets/home/one_1.png'),require('@/assets/home/one_2.png'),require('@/assets/home/one_3.png')],
      img2: [require('/src/assets/home/two_1.png'),require('@/assets/home/two_2.png'),require('@/assets/home/two_3.png'),require('@/assets/home/two_4.png')]
    }
  },
  methods: {
    toHome(){
      this.$router.push({path: "/"})
    },
    // 点击导航菜单，页面滚动到指定位置
    handleLeft(index) {
      this.navgatorIndex = index;

      this.$el.querySelector(`#id${index}`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState=false;

      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },200);
    },
    // 监听页面元素滚动，改变导航栏选中
    scrollToTop() {
      // 获取视窗高度
      //let domHight = document.body.offsetHeight;
      // dom滚动位置
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (this.listBoxState) {//作用是点击导航栏时，延迟这里执行。
        this.navlist.map((v,i) => {
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(`id${i}`).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(`id${i}`).scrollHeight;

          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
            // 导航栏背景色选中
            this.navgatorIndex = i;
          }
        })
      }
    },
    back(){
      this.$refs.swipe.prev();
    },
    next(){
      this.$refs.swipe.next();
    },
    getinfo(e){
      //console.log("active",e)
      this.activeitem = e;
    },
    imagePre(num,index){
      if (num == 1){
        ImagePreview({images: this.img1, startPosition: index});
      }else {
        ImagePreview({images: this.img2, startPosition: index});
      }

    },
    toDolown(){
      let url = "https://a.app.qq.com/o/simple.jsp?pkgname=com.fourbull.pm"
      window.open(url,'_blank');
    }
  }
}
</script>

<style scoped>
.topbg{background-image: url("../../assets/top.png");background-repeat: no-repeat;width: 100%;height: 400px;background-size: 100% 98%;}/**/

.topbgs{background-image: url("../../assets/top1.png");width: 100%;height: 250px;background-size: 100% 95%;background-repeat: no-repeat;}
.shadow{padding: 15px 10px;box-shadow: 0 0 5px #DCDCDC;background: #fff;}
.bgcri{width: 55px;height: 55px;border: 1px solid #619AFD;border-radius: 50%;margin: auto;}
.mt40{margin-top: 40px;}
/deep/.van-tab--active{color: #1890ff;font-weight: 500;}
/deep/.van-tabs__line{background-color: #1890ff;width: 20px;height: 4px}
.my-swipe .van-swipe-item {
  color: #333;
  font-size: 20px;
  text-align: center;
  background-color: #F4F7FC;
}
.line20{line-height: 20px;}
/deep/.van-swipe__indicator{background-color: #ffffff!important;}
/deep/.van-swipe__indicator--active{background-color: #1890ff!important;}
.border{border: 1px solid rgb(237,234,237);border-radius: 5px;width: 90%;height: 120px;margin: auto;}
.bors{width: 20px;height: 20px;border-radius: 4px;}
.bg1{background-color: rgb(253,123,112)}
.bg2{background-color: rgb(255,161,0)}
.bg3{background-color: rgb(137,146,246)}
.bg4{background-color: rgb(165,112,226)}
.bg5{background-color: rgb(96,144,240)}
.bg6{background-color: rgb(250,100,100)}
.bg7{background-color: rgb(253,127,72)}
.bg8{background-color: rgb(250,190,0)}
.wid75{width: 75vw;}
.ml2vw{margin-left: 1vw}
.wid20{width: 19vw;}
.bor70{width: 50px;height: 50px;border-radius: 50%;background: rgb(0,96,252);text-align: center;}
.line{width: 100%;height: 6px;background: rgb(0,96,252);}
.bor10{width: 25px;height: 25px;border-radius: 50%;background: rgb(0,96,252);}
.text{
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;

}
.bgimg{width: 30px;height: 30px;border-radius: 50%;box-shadow: 0 0 4px 2px rgb(221,223,228);}
.lineh50{line-height: 50px;}
.font34{font-size: 34px;}
.mt12{margin-top: 12px;}
.mr8{margin-right: 8px;}
</style>