<template>
  <div>
    <div style="position: relative;">
      <div class="topbg">
        <div class="w66 colw" style="margin: auto">
          <van-row class="padd15">
            <van-col span="6" class="">
              <van-icon :name="require('@/assets/sxlogo.png')" size="40"></van-icon>
              <span class="font26 ml7 fontblod" style="vertical-align: text-bottom;">SIXU</span>
<!--              <van-image :src="require('@/assets/banner.png')" height="85vh" width="98vw"></van-image>-->
            </van-col>
            <van-col span="2" class="font15 padd12 hometxt mycursor" @click="toHome">首页</van-col>
            <van-col span="16" class="font15 padd12">
              <van-row>
                <van-col v-for="(it,ke) in navlist" :key="ke"><span @click="handleLeft(ke)" class="mycursor mr50">{{ it }}</span></van-col>
              </van-row>
            </van-col>
          </van-row>
          <van-row class=" padd10" style="margin-top: 7vh">
            <van-col span="12" class="font34 fontblod lineh50">
              <p class="mt10">助力企业数智化</p>
              <p class="mt10">为中小企业提供高效的协同管理解决方案</p>
            </van-col>
          </van-row>
        </div>
      </div>

      <van-row id="id0" >
        <van-col span="24" class="mcenter">
          <p class="font26 mb20 mt20">应用可视化搭建，根据业务灵活搭建</p>
          <van-row  class="">
            <van-col span="24" style="width: 100%;display: flex;justify-content: center;">
              <div class="padd10" style="width: 300px;">
                <van-tabs v-model="active">
                  <van-tab title="表单设计"></van-tab>
                  <van-tab title="流程设计"></van-tab>
                  <van-tab title="报表设计"></van-tab>
                </van-tabs>
              </div>
            </van-col>
          </van-row>
          <div v-if="active == 0">
            <p class="font13 padd20 line20">丰富多样的表单组件，可自定义组件属性，如数据来源、规则校验等，移动端也支持表单基础配置</p>
            <div class="mt30">
              <van-image :src="require('@/assets/home/one_1.png')" width="60vw"></van-image>
            </div>
          </div>
          <div v-if="active == 1">
            <p class="font13 padd20 line20">图形式流程搭建，支持多种类型环节（如审批环节、处理环节、触发更新等）和各类复杂条件流程，满足多种业务场景需求</p>
            <div class="mt30">
              <van-image :src="require('@/assets/home/one_3.png')" width="60vw"></van-image>
            </div>
          </div>
          <div v-if="active == 2">
            <p class="font13 padd20 line20">自定义报表设计器，解决企业数据分析应用痛点（开发效率低，支付周期长，权限管理混乱），支持多维度数据分析，辅助管理决策</p>
            <div class="mt30">
              <van-image :src="require('@/assets/home/one_2.png')" width="60vw"></van-image>
            </div>
          </div>
        </van-col>
      </van-row>
      <div style="background-color: #F4F7FC;">
        <div class="padd20 mcenter mtb20" id="id1">
          <p class="font26 mt50 mb10">集成平台基础架构快速赋能</p>
          <p class="font13 padd30">提供底层基础功能支持，为企业协同高效管理保驾护航</p>
          <div class="padd20" style="width: 80vw;margin: auto;">
            <div class="ftleft" style="width: 16vw;">
              <div class="paddzy15">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img1.png')" height="50px" width="50px" class="mt40"></van-image>
                </div>
                <p class="font14 padd15">组织架构</p>
                <!--                <p class="font13 col6 lineh15">四序支持建立企业通讯录，流程审批科技与组织架构，方便、快捷</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 16vw">
              <div class="paddzy15">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img2.png')" height="50px" width="50px" class="mt40"></van-image>
                </div>
                <p class="font14 padd15">权限管理</p>
                <!--                <p class="font13 col6 lineh15">权限严格，读写查分权明确，组织、角色、操作、模块、对象、数据等权限的任意组合，有效支撑各种权限需求</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 16vw">
              <div class="paddzy15">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img3.png')" height="50px" width="50px" class="mt40"></van-image>
                </div>
                <p class="font14 padd15">安全聊天</p>
                <!--                <p class="font13 col6 lineh15">基于组织架构，通知、预警、里程碑等多种消息根据具体需求和场景随意配置</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 16vw">
              <div class="paddzy15">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img4.png')" height="50px" width="50px" class="mt40"></van-image>
                </div>
                <p class="font14 padd15">知识库</p>
                <!--                <p class="font13 col6 lineh15">打通百度网盘，无缝对接授权企业网盘，轻松实现企业文档、协同文档、项目文档等上传、下载、查看一站式管理</p>-->
              </div>
            </div>
            <div class="ftleft" style="width: 16vw">
              <div class="paddzy15">
                <div class="bgcri mcenter">
                  <van-image :src="require('@/assets/home/img5.png')" height="50px" width="50px" class="mt40"></van-image>
                </div>
                <p class="font14 padd15">工作台</p>
                <!--                <p class="font13 col6 lineh15">多样化工作台，实现以事情为中心，无需切换组织也可以轻松处理待办事项和接受消息提醒</p>-->
              </div>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div class="padd10"></div>
        <div class=" mcenter">
          <div class="font26 mt50 mb10">平台特色管理模式</div>
          <div class="padd30 font13">对比市场其他同类竞品，四序拥有独特的管理模式，打破传统固有思想，为客户创造价值，让企业更加智慧，助力企业实现数智化</div>
          <div class="padd20">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" ref="swipe">
              <van-swipe-item v-for="(item,key) in swipes" :key="key">
                <div class="" style="width: 80vw;margin: auto">
                  <van-row>
                    <van-col style="width: 500px;">
                      <van-image v-if="key == 0" :src="require('@/assets/home/two_1.png')" height="400px" width="500px"></van-image>
                      <van-image v-else-if="key == 1" :src="require('@/assets/home/two_2.png')" height="400px" width="500px"></van-image>
                      <van-image v-else-if="key == 2" :src="require('@/assets/home/two_3.png')" height="400px" width="500px"></van-image>
                      <van-image v-else-if="key == 3" :src="require('@/assets/home/two_4.png')" height="400px" width="500px"></van-image>
                    </van-col>
                    <van-col style="width: calc(100% - 500px);">
                      <div class="paddzy25 mleft">
                        <p class="font18 padd20 mt30">{{ item.title }}</p>
                        <p class="font14 line20">{{ item.text }}</p>
                      </div>
                      <div class="padd30 mt50"></div>
                      <div class="mt50 paddzy25 mr50">
                        <div class="bgimg ftright ml20" @click="next">
                          <van-icon name="arrow" color="#1890ff" class="mt7"/>
                        </div>
                        <div class="bgimg ftright" @click="back">
                          <van-icon name="arrow-left" color="#1890ff" class="mt7"/>
                        </div>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>

        </div>
        <div style="height: 350px;">
          <van-image :src="require('@/assets/home/back.png')" style="height: 350px;width: 100%;"></van-image>
        </div>
      </div>
      <div class="mt50 mcenter">
        <p class="font26 padd20">开箱即用的解决方案</p>
        <p class="font13 padd20 mb30">行业化解决方案，并能根据您的需求提供灵活、高效的贴身定制服务，让最终交付的方案能够完全贴合您的业务</p>
        <div style="width: 80vw;margin: auto;" class="text">
          <van-row class="mleft mb30" v-for="(ite,ke) in list" :key="ke">
            <van-col span="8" class="" v-for="(it,k) in ite" :key="k">
              <div class="border">
                <div class="p20">
                  <div class="">
                    <div :class="['ftleft','bors', it.bg]"></div>
                    <div class="ftleft ml10">{{ it.title }}</div>
                    <div class="clear"></div>
                  </div>
                  <div class="font12 line20  mt20 text">{{ it.text }}</div>
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="mt50 mcenter bgwhite" id="id2">
        <p class="font24 padd30 mb30">实施保障</p>
        <div class="wid75" style="margin: auto;height: 250px;">
          <div class="wid15 ftleft mcenter" v-for="(item,key) in step" :key="key">
            <div class="bor70" style="margin: auto;">
              <van-icon v-if="key==0" :name="require('@/assets/home/icon1.png')" size="50" class="mt10"></van-icon>
              <van-icon v-if="key==1" :name="require('@/assets/home/icon2.png')" size="50" class="mt10"></van-icon>
              <van-icon v-if="key==2" :name="require('@/assets/home/icon3.png')" size="50" class="mt10"></van-icon>
              <van-icon v-if="key==3" :name="require('@/assets/home/icon4.png')" size="50" class="mt10"></van-icon>
              <van-icon v-if="key==4" :name="require('@/assets/home/icon5.png')" size="48" class="mt15"></van-icon>
            </div>
            <div class="padd20 mt10">
              <div class="line" style="display: flex;justify-content: center;position: relative;">
                <div class="bor10 mcenter colw" style="position: absolute;top: -12px;" >
                  <span style="position: absolute;top: 6px;left: 10px;">{{ key + 1 }}</span>
                </div>
              </div>
            </div>

            <div class="padd20 font16">{{ item.title }}</div>
            <div class="paddzy20 line20 font13">{{ item.text }}</div>
          </div>
        </div>
        <!--        <van-image :src="require('@/assets/home/bottom.png')" style="height: 600px;width: 100%;"></van-image>-->
      </div>
      <van-row class="mt50" style="background-color: #F4F7FC;">
        <van-col span="24" class="mcenter mt20">
          <p class="padd20">成都四小牛科技有限公司</p>
          <a href="https://beian.miit.gov.cn/">蜀ICP备2021025007号-1</a>
        </van-col>
      </van-row>
      <div style="padding: 10px;background-color: #F4F7FC;"></div>
    </div>

    <div style="position: fixed;top: 35vh;right: 20px;" class="shadow mcenter">
      <van-image :src="require('@/assets/sixu2.png')" height="9vw" width="9vw"></van-image>
      <p class="mcenter font14 paddbot15">联系我们</p>
      <van-image :src="require('@/assets/sixuma.png')" height="10vw" width="10vw"></van-image>
      <p class="mcenter font14">关注公众号</p>
    </div>
    <!--
        <div style="position: fixed;bottom: 2vh;right: 20px;">

        </div>
    -->

  </div>
</template>

<script>
export default {
  name: "index",
  props: ['list','swipes','step','navlist'],
  data(){
    return{
      active: "",
      activeitem: 0,
      navgatorIndex: 0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      dis: 0,
      currentwidth: window.document.body.offsetWidth,
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      that.currentwidth = window.document.body.offsetWidth;
      //console.log("width",that.width);
      /*if (that.currentwidth < 900){
        that.dis = 1;
      }else {
        that.dis = 0;
      }*/
      //console.log("dis",that.dis);
    }
    let timeId;
    window.addEventListener('scroll', () => {
      // 页面滚动停止100毫秒后才会执行下面的函数。
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        //this.scrollToTop();
        //console.log('执行完了哦');
      }, 100);
    } , true);
  },
  /*watch: {
    width(val) {
      let that = this;
      if (val < 900){
        that.dis = 1;
      }else {
        that.dis = 0;
      }
    },
  },*/
  methods: {
    toHome(){
      this.$router.push({path: "/"})
    },
    // 点击导航菜单，页面滚动到指定位置
    handleLeft(index) {
      this.navgatorIndex = index;

      this.$el.querySelector(`#id${index}`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState=false;

      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },200);
    },
    // 监听页面元素滚动，改变导航栏选中
    scrollToTop() {
      // 获取视窗高度
      //let domHight = document.body.offsetHeight;
      // dom滚动位置
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (this.listBoxState) {//作用是点击导航栏时，延迟这里执行。
        this.navlist.map((v,i) => {
          // 获取监听元素距离视窗顶部距离
          let offsetTop = 0;
          if (document.getElementById(`id${i}`).offsetTop){
            offsetTop = document.getElementById(`id${i}`).offsetTop;
          }
          // 获取监听元素本身高度
          let scrollHeight = document.getElementById(`id${i}`).scrollHeight;

          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
            // 导航栏背景色选中
            this.navgatorIndex = i;
          }
        })
      }
    },
    back(){
      this.$refs.swipe.prev();
    },
    next(){
      this.$refs.swipe.next();
    },
    getinfo(e){
      //console.log("active",e)
      this.activeitem = e;
    },
  }
}
</script>

<style lang="less" scoped>
.topbg{background-image: url("../../assets/top.png");background-repeat: no-repeat;width: 100%;height: 90vh;background-size: 100% 98%;}/**/
.w66{width: 66vw;}
.shadow{padding: 15px 10px;box-shadow: 0 0 5px #DCDCDC;background: #fff;}
.bgcri{width: 130px;height: 130px;border: 1px solid #619AFD;border-radius: 50%;margin: auto;}
.mt40{margin-top: 40px;}
/deep/.van-tab--active{color: #1890ff;font-weight: 500;}
/deep/.van-tabs__line{background-color: #1890ff;width: 20px;height: 4px}
.my-swipe .van-swipe-item {
  color: #333;
  font-size: 20px;
  text-align: center;
  background-color: #F4F7FC;
}
.line20{line-height: 20px;}
/deep/.van-swipe__indicator{background-color: #ffffff!important;}
/deep/.van-swipe__indicator--active{background-color: #1890ff!important;}
.border{border: 1px solid rgb(237,234,237);border-radius: 5px;width: 90%;height: 160px;}
.bors{width: 25px;height: 25px;border-radius: 5px;}
.bg1{background-color: rgb(253,123,112)}
.bg2{background-color: rgb(255,161,0)}
.bg3{background-color: rgb(137,146,246)}
.bg4{background-color: rgb(165,112,226)}
.bg5{background-color: rgb(96,144,240)}
.bg6{background-color: rgb(250,100,100)}
.bg7{background-color: rgb(253,127,72)}
.bg8{background-color: rgb(250,190,0)}
.wid75{width: 75vw;}
.wid15{width: 15vw;}
.bor70{width: 70px;height: 70px;border-radius: 50%;background: rgb(0,96,252);text-align: center;}
.line{width: 100%;height: 8px;background: rgb(0,96,252);}
.bor10{width: 30px;height: 30px;border-radius: 50%;background: rgb(0,96,252);}
.text{
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;

}
.bgimg{width: 35px;height: 35px;border-radius: 50%;box-shadow: 0 0 4px 2px rgb(221,223,228);}
.lineh50{line-height: 50px;}
.font34{font-size: 34px;}
.hometxt:hover{color: #0066ff;}
</style>