<template>
  <div>
    <div style="position: relative;">
      <div v-if="currentwidth < 850">
        <h5-index :navlist="navlist" :list="list" :step="step" :swipes="swipes" :cwidth="currentwidth"></h5-index>
      </div>
      <div v-else>
        <web-index :navlist="navlist" :list="list" :step="step" :swipes="swipes"></web-index>
      </div>
    </div>
<!--    <div style="position: fixed;top: 35vh;right: 20px;" class="shadow mcenter">
      <van-image :src="require('@/assets/sixu2.png')" height="9vw" width="9vw"></van-image>
      <p class="mcenter font14">联系我们</p>
      <van-image :src="require('@/assets/sixu.jpg')" height="10vw" width="10vw"></van-image>
      <p class="mcenter font14">关注公众号</p>
    </div>-->

  </div>
</template>

<script>
import webIndex from "@/views/newhome/index";//
import h5Index from "@/views/newhome/h5Index";
export default {
  name: "newhome",
  components: {
    webIndex,
    h5Index,
  },
  data(){
    return{
      active: "",
      activeitem: 0,
      list: [
          [
            {bg: 'bg4',title: "工程项目管理",text: "招投标、立项、方案设计、准备、执行、监控、结项；个性化设置流程"},
            {bg: 'bg2',title: "招投标管理",text: "投标过程节点（做资料、封标等）落实到人，开标时间全程（打保证金、做资料、封开、开标）预警提醒，成本报表展示各环节产生费用统计和明细"},
            {bg: 'bg3',title: "供应商管理",text: "实现供应商全方位闭环管理，提高采购效率，降低采购成本"},
          ],
        [
          {bg: 'bg1',title: "进销存",text: "供应商、采购、库存、销售、财务、内部管理等全场景覆盖，实现进销存闭环管理；支持完全自定义，就能制造企业高效管理"},
          {bg: 'bg5',title: "设备巡检",text: "一物一码，一台手机就能查看最新档案；设备档案管理、保修、巡检、保养，全流程覆盖，随时监控"},
          {bg: 'bg6',title: "生产管理",text: "设备维护、销售订单、物料分析、原料采购、生产计划及实施、质量检测、库存及财务管理，一应俱全的生产管理"},
        ],
        [
          {bg: 'bg7',title: "客户管理",text: "从线索到回款，赢单效率大幅提升；移动办公，无需电脑；短信、邮件、微信，多种消息触达客户；多维度报表，助力精细化运营"},
          {bg: 'bg8',title: "售后工单",text: "只能派单、服务监控、费用结算、客户反馈、客户、备件、网点管理，无死角覆盖售后全场景"},
        ],
      ],
      swipes: [
        {icon: '@/assets/home/icon1.png',title: "多级子应用",text: "四序支持创建多级子应用，可实现对项目内部流程审批、协同办公、数据统计等管理，满足企业场景化、专业化、碎片化的项目管理需求"},
        {icon: '@/assets/home/icon2.png',title: "支持外部数据源、表单云收集",text: "四序支持表单数据来源于外部，打造与第三方其他数之间的壁垒，此外四序也支持通过链接、二维码、应用内部流转等方式将我方表单分享到好友、组织内部、外部环境等"},
        {icon: '@/assets/home/icon3.png',title: "文档管理 打通百度网盘",text: "可授权百度网盘企业账号，针对读写权限重新授权管理，公司文件、项目文件、写作文件无缝对接，轻松实现文档在线管理"},
        {icon: '@/assets/home/icon4.png',title: "数据变更、操作记录、应用配置可追溯",text: "无论是表单修改日志、流程中数据变更日志、流程操作日志、还是应用配置版本记录，四序都有全方位监控的日志引擎"},
      ],
      step: [
        {icon: '@/assets/home/icon1.png',title: "调研",text: "前期客户业务及需求深入调研"},
        {icon: '@/assets/home/icon2.png',title: "设计",text: "根据业务场景快速搭建管理应用"},
        {icon: '@/assets/home/icon3.png',title: "试运行",text: "初步进行小范围试运行"},
        {icon: '@/assets/home/icon4.png',title: "修订",text: "根据现场反馈与需求变更进行调整"},
        {icon: '@/assets/home/icon5.png',title: "投运",text: "正式进入运营使用"},
      ],
      navlist: ["产品详情","解决方案","实施方案"],
      navgatorIndex: 0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      dis: 0,
      currentwidth: window.document.body.offsetWidth,
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      that.currentwidth = window.document.body.offsetWidth;
      //console.log("width",that.currentwidth);
      /*if (that.currentwidth < 860){
        that.dis = 1;
      }else {
        that.dis = 0;
      }*/
      //console.log("dis",that.dis);
    }
    console.log("width",that.currentwidth)
    /*let timeId;
    window.addEventListener('scroll', () => {
      // 页面滚动停止100毫秒后才会执行下面的函数。
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.scrollToTop();
        //console.log('执行完了哦');
      }, 100);
    } , true);*/
  },
  watch: {
    currentwidth(val) {
      let that = this;
      if (val < 860){
        that.dis = 1;
      }else {
        that.dis = 0;
      }
    },
  },
  methods: {
    // 点击导航菜单，页面滚动到指定位置
    handleLeft(index) {
      this.navgatorIndex = index;

      this.$el.querySelector(`#id${index}`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState=false;

      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },200);
    },
    // 监听页面元素滚动，改变导航栏选中
    scrollToTop() {
      // 获取视窗高度
      //let domHight = document.body.offsetHeight;
      // dom滚动位置
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (this.listBoxState) {//作用是点击导航栏时，延迟这里执行。
        this.navlist.map((v,i) => {
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(`id${i}`).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(`id${i}`).scrollHeight;

          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
            // 导航栏背景色选中
            this.navgatorIndex = i;
          }
        })
      }
    },
    back(){
      this.$refs.swipe.prev();
    },
    next(){
      this.$refs.swipe.next();
    },
    getinfo(e){
      //console.log("active",e)
      this.activeitem = e;
    },
  }
}
</script>

<style lang="less" scoped>
.topbg{background-image: url("../assets/top.png");background-repeat: no-repeat;width: 100%;height: 90vh;background-size: 100% 98%;}/**/
.w66{width: 66vw;}
.mcenter{text-align: center;}
.mt10{margin-top: 10px;}
.font14{font-size: 14px;}
.ml10{margin-left: 10px;}
.shadow{padding: 15px 10px;box-shadow: 0 0 5px #DCDCDC;background: #fff;}
.bgcri{width: 130px;height: 130px;border: 1px solid #619AFD;border-radius: 50%;margin: auto;}
.mt40{margin-top: 40px;}
/deep/.van-tab--active{color: #1890ff;font-weight: 500;}
/deep/.van-tabs__line{background-color: #1890ff;width: 20px;height: 4px}
.my-swipe .van-swipe-item {
  color: #333;
  font-size: 20px;
  text-align: center;
  background-color: #F4F7FC;
}
.line20{line-height: 20px;}
/deep/.van-swipe__indicator{background-color: #ffffff!important;}
/deep/.van-swipe__indicator--active{background-color: #1890ff!important;}
.border{border: 1px solid rgb(237,234,237);border-radius: 5px;width: 90%;height: 160px;}
.bors{width: 25px;height: 25px;border-radius: 5px;}
.bg1{background-color: rgb(253,123,112)}
.bg2{background-color: rgb(255,161,0)}
.bg3{background-color: rgb(137,146,246)}
.bg4{background-color: rgb(165,112,226)}
.bg5{background-color: rgb(96,144,240)}
.bg6{background-color: rgb(250,100,100)}
.bg7{background-color: rgb(253,127,72)}
.bg8{background-color: rgb(250,190,0)}
.wid75{width: 75vw;}
.wid15{width: 15vw;}
.bor70{width: 70px;height: 70px;border-radius: 50%;background: rgb(0,96,252);text-align: center;}
.line{width: 100%;height: 8px;background: rgb(0,96,252);}
.bor10{width: 30px;height: 30px;border-radius: 50%;background: rgb(0,96,252);}
.text{
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;

}
.bgimg{width: 35px;height: 35px;border-radius: 50%;box-shadow: 0 0 4px 2px rgb(221,223,228);}
.lineh50{line-height: 50px;}
.font34{font-size: 34px;}
</style>